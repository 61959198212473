import { makeFeature } from 'services/Feature';

export const feature = makeFeature({
  name: 'MESCHFooter',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "mesch-footer" */
      './Component'
    ),
  i18nData: null,
  i18nSharedReferences: null,
  subfeatures: [],
  clientOnly: true,
});

export const Component = feature.Component;
