const config = {
  GLOBALLAB: {
    title: 'ГлобалЛаб',
    projectBook: {
      intro: {
        type: true,
        isIndividual: true,
        template: true,
      },
      page: {
        passport: {
          fields: {
            level: true,
          },
        },
        members: {
          supervisors: {
            addButton: true,
          },
          watchers: true,
          groups: true,
        },
        stages: {
          tasks: {
            self: true,
            board: true,
            gantt: true,
            table: true,
          },
          discussion: true,
          materials: {
            comments: true,
            type: {
              file: true,
              text: true,
              link: true,
              project: {
                self: true,
                constructor: {
                  tabs: {
                    accessTab: true,
                  },
                },
                copyProjectLinkButton: true,
              },
              mesch: false,
            },
          },
        },
        results: {
          self: true,
          download: true,
        },
        evaluation: {
          self: true,
          statistics: {
            self: true,
            LLM: true,
          },
          evaluation: true,
        },
        reflexion: true,
        history: true,
        notifications: true,
        recycleBin: true,
      },
      concurrency: true,
    },
  },
  GLOBALLAB_ONLINE: {
    title: 'Globallab',
    projectBook: {
      intro: {
        type: true,
        isIndividual: true,
        template: true,
      },
      page: {
        passport: {
          fields: {
            level: true,
          },
        },
        members: {
          supervisors: {
            addButton: true,
          },
          watchers: true,
          groups: true,
        },
        stages: {
          tasks: {
            self: true,
            board: true,
            gantt: true,
            table: true,
          },
          discussion: true,
          materials: {
            comments: true,
            type: {
              file: true,
              text: true,
              link: true,
              project: {
                self: true,
                constructor: {
                  tabs: {
                    accessTab: true,
                  },
                },
                copyProjectLinkButton: true,
              },
              mesch: false,
            },
          },
        },
        results: {
          self: true,
          download: true,
        },
        evaluation: {
          self: true,
          statistics: {
            self: true,
            LLM: true,
          },
          evaluation: true,
        },
        reflexion: true,
        history: true,
        notifications: true,
        recycleBin: true,
      },
      concurrency: true,
    },
  },
  MESCH: {
    title: 'ГлобалЛаб',
    projectBook: {
      intro: {
        type: false,
        isIndividual: true,
        template: false,
      },
      page: {
        passport: {
          fields: {
            level: false,
          },
        },
        members: {
          supervisors: {
            addButton: false,
          },
          watchers: false,
          groups: false,
        },
        stages: {
          tasks: {
            self: false,
            board: false,
            gantt: false,
            table: false,
          },
          discussion: true,
          materials: {
            comments: true,
            type: {
              file: true,
              text: false,
              link: true,
              project: {
                self: true,
                constructor: {
                  tabs: {
                    accessTab: false,
                  },
                },
                copyProjectLinkButton: false,
              },
              mesch: true,
            },
          },
        },
        results: {
          self: true,
          download: false,
        },
        evaluation: {
          self: false,
          statistics: {
            self: false,
            LLM: false,
          },
          evaluation: false,
        },
        reflexion: true,
        history: true,
        notifications: false,
        recycleBin: true,
      },
      concurrency: false,
    },
  },
  ALPHA2: {
    title: 'ГлобалЛаб',
    projectBook: {
      intro: {
        type: false,
        isIndividual: true,
        template: false,
      },
      page: {
        passport: {
          fields: {
            level: false,
          },
        },
        members: {
          supervisors: {
            addButton: false,
          },
          watchers: false,
          groups: false,
        },
        stages: {
          tasks: {
            self: false,
            board: false,
            gantt: false,
            table: false,
          },
          discussion: true,
          materials: {
            comments: true,
            type: {
              file: true,
              text: false,
              link: true,
              project: {
                self: true,
                constructor: {
                  tabs: {
                    accessTab: false,
                  },
                },
                copyProjectLinkButton: false,
              },
              mesch: true,
            },
          },
        },
        results: {
          self: true,
          download: false,
        },
        evaluation: {
          self: false,
          statistics: {
            self: false,
            LLM: false,
          },
          evaluation: false,
        },
        reflexion: true,
        history: true,
        notifications: false,
        recycleBin: true,
      },
      concurrency: false,
    },
  },
};

export const profileConfig = config[process.env.RAZZLE_PROFILE];
