import React from 'react';

import { Preloader } from 'components';
import * as TS from 'types';
import { makePrimaryUnit } from 'utils/State';

import * as I18n from '../I18n';
import { flattenFeatures } from './flattenFeatures';
import { makeComponent } from './makeComponent';
import { ComponentModule, Feature, Mode } from './types';

export type FeatureConstructorArgs<ComponentProps> = {
  name: string;
  componentModuleLoader: () => Promise<ComponentModule<ComponentProps>>;
  subfeatures: Array<Feature<any>>;
  Preloader?: React.FC;
  prefetched?: boolean;
  deepPrefetched?: boolean;
  preloaded?: boolean;
  clientOnly?: boolean;
  i18nData: TS.I18nData | null;
  i18nSharedReferences: I18n.EntryReference[] | null;
};

export const featureNamesSet: Set<string> = new Set();

export function makeFeature<T extends Record<string, any>>(
  args: FeatureConstructorArgs<T>,
): Feature<T> {
  const {
    componentModuleLoader,
    name,
    subfeatures,
    Preloader: PreloaderArg = Preloader.Component,
    prefetched = false,
    deepPrefetched = false,
    preloaded = false,
    clientOnly = false,
    i18nData,
    i18nSharedReferences,
  } = args;

  if (featureNamesSet.has(name)) {
    throw new Error(`duplicate feature name ${name}`);
  } else {
    featureNamesSet.add(name);
  }

  const clientOnlyFlatSubFeaturesSet = new Set<Feature<any>['name']>();

  const flatSubFeatures = flattenFeatures(subfeatures, subFeature => {
    if (subFeature.clientOnly) {
      clientOnlyFlatSubFeaturesSet.add(subFeature.name);

      flattenFeatures(subFeature.subfeatures).forEach(x => {
        clientOnlyFlatSubFeaturesSet.add(x.name);
      });
    }
  });

  const modeUnit = makePrimaryUnit<Mode<T>>({ kind: 'initial' });

  const loadModule = async (): Promise<void> => {
    modeUnit.setState({ kind: 'pending' });
    const module = await componentModuleLoader();
    modeUnit.setState({ kind: 'loaded', Component: module.Component });
  };

  if (process.env.BUILD_TARGET === 'server' && deepPrefetched) {
    const prefetchedFlatSubFeatures = flatSubFeatures.filter(
      x => !clientOnlyFlatSubFeaturesSet.has(x.name),
    );

    prefetchedFlatSubFeatures.map(x => x.loadComponentModule());

    loadModule();
  }

  if (process.env.BUILD_TARGET === 'client' && preloaded) {
    flatSubFeatures.map(x => x.loadComponentModule());

    loadModule();
  }

  const Component = makeComponent(
    loadModule,
    modeUnit,
    PreloaderArg,
    prefetched,
    name,
  );

  return {
    Component,
    name,
    prefetched,
    deepPrefetched,
    clientOnly,
    subfeatures,
    i18nData,
    loadComponentModule: loadModule,
    i18nSharedReferences,
  };
}
