import React, { useMemo, useEffect } from 'react';

import { I18n, API, Page, DataPrefetch } from 'services';
import { PrimaryStateUnit } from 'utils/State';

import { routeTree } from '../routeTree';
import * as features from './features';

const homePagePrefetcher = DataPrefetch.makeDataPrefetcher(
  'homePage',
  routeTree.LANG.getPath(),
  ({ lang }) => {
    const code = (() => {
      const codes = JSON.parse(process.env.RAZZLE_HOME_PAGE_CODES);

      const currentLangCode = codes[lang];

      if (currentLangCode) {
        return currentLangCode;
      }

      const defaultLang = I18n.constants.languages[0];

      if (defaultLang && codes[defaultLang]) {
        return codes[defaultLang];
      }

      return Object.values(codes)[0];
    })();

    return API.services.landingPage.get.callPromised({
      code,
    });
  },
);

function Home() {
  const lang = I18n.activeLangStateUnit.useState();

  const callStateUnit = API.services.landingPage.get.useCallStateUnit();
  const callState = callStateUnit.useState();
  const call = API.services.landingPage.get.useCall(callStateUnit);

  const code = useMemo(() => {
    const codes = JSON.parse(process.env.RAZZLE_HOME_PAGE_CODES);

    const currentLangCode = codes[lang];

    if (currentLangCode) {
      return currentLangCode;
    }

    const defaultLang = I18n.constants.languages[0];

    if (defaultLang && codes[defaultLang]) {
      return codes[defaultLang];
    }

    const code = Object.values(codes)[0];

    if (code) {
      return code;
    }

    return null;
  }, [lang]);

  const landingPage: features.Page.Props['landingPage'] = useMemo(() => {
    const prefetchedHomePage = homePagePrefetcher.getData();

    if (prefetchedHomePage !== DataPrefetch.notFetched) {
      return { kind: 'prefetched', value: prefetchedHomePage };
    }

    return { kind: 'default', value: callState };
  }, [callState]);

  useEffect(() => {
    if (homePagePrefetcher.getData() !== DataPrefetch.notFetched) {
      return;
    }

    if (!code) {
      return;
    }

    call({ code });
  }, [code, call]);

  useEffect(
    () => () => {
      homePagePrefetcher.deleteData();
    },
    [],
  );

  return (
    <features.Page.Component
      landingPage={landingPage}
      hashParamUnit={
        routeTree.LANG.hashParamUnit as PrimaryStateUnit<'' | `#${string}`>
      }
      withDefaultTopElements
      withDefaultFooterElements
      withHelmet
    />
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG.getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(Home),
  features: Object.values(features).map(x => x.feature),
});
