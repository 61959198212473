import * as R from 'ramda';

import { Feature } from '../Feature';

export function flattenFeatures(
  features: Array<Feature<any>>,
  callback?: (feature: Feature<any>) => void,
): Array<Feature<any>> {
  return R.uniqBy(
    x => x.name,
    features.flatMap((x): Array<Feature<any>> => {
      callback?.(x);

      return [x, ...flattenFeatures(x.subfeatures)];
    }),
  );
}
