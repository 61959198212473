import React, { useMemo, useEffect } from 'react';

import { routeTree } from 'pages/routeTree';
import { API, DataPrefetch, Page } from 'services';

import * as features from './features';

const landingPagePrefetcher = DataPrefetch.makeDataPrefetcher(
  'landingPage',
  routeTree.LANG['landing-page'].LANDING_PAGE_CODE.getPath() + '/:rest*',
  ({ landing_page_code }) => {
    return API.services.landingPage.get.callPromised({
      code: landing_page_code,
    });
  },
);

function LandingPage() {
  const routeParams =
    routeTree.LANG['landing-page'].LANDING_PAGE_CODE.useRouteParams();
  const code = routeParams && routeParams.landing_page_code;

  const callStateUnit = API.services.landingPage.get.useCallStateUnit();
  const callState = callStateUnit.useState();
  const call = API.services.landingPage.get.useCall(callStateUnit);

  const landingPage: features.Page.Props['landingPage'] = useMemo(() => {
    const prefetchedLandingPage = landingPagePrefetcher.getData();

    if (prefetchedLandingPage !== DataPrefetch.notFetched) {
      return { kind: 'prefetched', value: prefetchedLandingPage };
    }

    return { kind: 'default', value: callState };
  }, [callState]);

  useEffect(() => {
    if (landingPagePrefetcher.getData() !== DataPrefetch.notFetched) {
      return;
    }

    if (!code) {
      return;
    }

    call({ code });
  }, [code, call]);

  useEffect(
    () => () => {
      landingPagePrefetcher.deleteData();
    },
    [],
  );

  return (
    <features.Page.Component
      landingPage={landingPage}
      hashParamUnit={
        routeTree.LANG['landing-page'].LANDING_PAGE_CODE.hashParamUnit
      }
      withHelmet
    />
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG['landing-page'].LANDING_PAGE_CODE.getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(LandingPage),
  features: Object.values(features).map(x => x.feature),
});
