import React, { useMemo, useEffect } from 'react';

import { routeTree } from 'pages/routeTree';
import { API, DataPrefetch, Page } from 'services';

import * as features from './features';

type Props = Omit<features.Topic.Props, 'topic' | 'withHelmet'>;

export const path = routeTree.LANG.handbook.CODE.topic.TOPIC.getPath();

const topicPrefetcher = DataPrefetch.makeDataPrefetcher(
  'handBookTopic',
  path + '/:rest*',
  ({ topic }) => {
    return API.services.article.callPromised({
      code: topic,
    });
  },
);

function Topic(props: Props) {
  const routeParams = routeTree.LANG.handbook.CODE.topic.TOPIC.useRouteParams();
  const code = (() => {
    if (routeParams) {
      return routeParams.topic;
    }
    return null;
  })();

  const callStateUnit = API.services.article.useCallStateUnit();
  const callState = callStateUnit.useState();
  const call = API.services.article.useCall(callStateUnit);

  const topic: features.Topic.Props['topic'] = useMemo(() => {
    const prefetchedTopic = topicPrefetcher.getData();

    if (prefetchedTopic !== DataPrefetch.notFetched) {
      return { kind: 'prefetched', value: prefetchedTopic };
    }

    return { kind: 'default', value: callState };
  }, [callState]);

  useEffect(() => {
    if (topicPrefetcher.getData() !== DataPrefetch.notFetched) {
      return;
    }

    if (!code) {
      return;
    }

    call({ code });
  }, [code, call]);

  useEffect(
    () => () => {
      topicPrefetcher.deleteData();
    },
    [code],
  );

  return <features.Topic.Component topic={topic} withHelmet {...props} />;
}

export const Component = Page.makePage({
  path,
  routeProps: { exact: false },
  scrollTo: 'top-on-mount',
  Component: React.memo(Topic) as typeof Topic,
  features: Object.values(features).map(x => x.feature),
}) as typeof Topic;
