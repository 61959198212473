import { USER_ROLE } from 'shared/constants';
import * as M from 'types/serverModels';
import { makeDerivedUnit, makePrimaryUnit } from 'utils/State';
import { makeMapping } from 'utils/collection';

import * as F from '../FormWithSteps';
import { ConstructorMode } from '../types';
import { ConstructorSteps } from './Steps';

const keyToStep: Record<F.StepKey, F.Step> = {
  access: F.steps.Access.step,
  advancedSettings: F.steps.AdvancedSettings.step,
  concept: F.steps.Concept.step,
  conclusions: F.steps.Conclusions.step,
  findingsSettings: F.steps.FindingsSettings.step,
  inputDataForm: F.steps.InputDataForm.step,
  instruction: F.steps.Instruction.step,
  participantMaterials: F.steps.ParticipantMaterials.step,
  projectAnnouncement: F.steps.ProjectAnnouncement.step,
  recommendations: F.steps.Recommendations.step,
  teacherMaterials: F.steps.TeacherMaterials.step,
};

export const defaultCompactConstructorStepSections = [
  [
    F.steps.Concept.step,
    F.steps.InputDataForm.step,
    F.steps.Instruction.step,
    F.steps.FindingsSettings.step,
    F.steps.ProjectAnnouncement.step,
  ],
];

export const compactConstructorStepSections = {
  default: defaultCompactConstructorStepSections,
  moderator: [
    ...defaultCompactConstructorStepSections,
    [F.steps.AdvancedSettings.step],
  ],
};

export const defaultFullConstructorStepSections = [
  [
    F.steps.Concept.step,
    F.steps.InputDataForm.step,
    F.steps.Instruction.step,
    F.steps.FindingsSettings.step,
    F.steps.Conclusions.step,
    F.steps.ProjectAnnouncement.step,
  ],

  [F.steps.TeacherMaterials.step, F.steps.ParticipantMaterials.step],

  [F.steps.Access.step, F.steps.Recommendations.step],
];

export const fullConstructorStepSections = {
  default: defaultFullConstructorStepSections,
  moderator: [
    ...defaultFullConstructorStepSections,
    [F.steps.AdvancedSettings.step],
  ],
};

const makeStepSections = (mode: ConstructorMode, user: M.User | null) => {
  switch (mode) {
    case 'compact': {
      if (user?.role === USER_ROLE.moderator) {
        return compactConstructorStepSections['moderator'];
      }
      return compactConstructorStepSections['default'];
    }
    case 'full': {
      if (user?.role === USER_ROLE.moderator) {
        return fullConstructorStepSections['moderator'];
      }
      return fullConstructorStepSections['default'];
    }
  }
};

type Args = {
  user: M.User;
  mode: ConstructorMode;
  customStepSections?: (
    keyToStep: Record<F.StepKey, F.Step>,
  ) => F.StepsSection[];
};

export function makeConstructorSteps({
  mode,
  user,
  customStepSections,
}: Args): ConstructorSteps | null {
  const stepsSections =
    typeof customStepSections === 'function'
      ? customStepSections(keyToStep)
      : makeStepSections(mode, user);

  const steps = stepsSections.flat();

  const stepKeyToStep = makeMapping(
    steps,
    x => x.key,
    x => x,
  );

  const activeStepUnit = makePrimaryUnit<F.StepKey>(
    process.env.RAZZLE_MOCK_CONSTRUCTOR_DATA === 'yes'
      ? mode === 'compact'
        ? 'findingsSettings'
        : 'findingsSettings'
      : steps[0].key,
  );

  const isLastStepUnit = makeDerivedUnit(activeStepUnit).getUnit(
    active => steps[steps.length - 1].key === active,
  );

  return {
    steps,
    stepKeyToStep,
    activeStepUnit,
    isLastStepUnit,
    stepsSections,
  };
}
