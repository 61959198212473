import React, { useMemo, useEffect } from 'react';

import { API, Page } from 'services';

import { routeTree } from '../routeTree';
import * as features from './features';

function ArticlePage() {
  const routeParams = routeTree.LANG.article.CODE.useRouteParams();
  const code = routeParams && routeParams.code;

  const callStateUnit = API.services.article.useCallStateUnit();
  const callState = callStateUnit.useState();
  const call = API.services.article.useCall(callStateUnit);

  const topic: features.Topic.Props['topic'] = useMemo(() => {
    return { kind: 'default', value: callState };
  }, [callState]);

  useEffect(() => {
    if (!code) {
      return;
    }

    call({ code });
  }, [code, call]);

  return <features.Topic.Component topic={topic} />;
}

export const Component = Page.makePage({
  path: routeTree.LANG.article.CODE.getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(ArticlePage),
  features: Object.values(features).map(x => x.feature),
});
