import React from 'react';

import * as features from './features';

type Props = Omit<features.View.Props, 'withHelmet'>;

function Webinar(props: Props) {
  return <features.View.Component withHelmet {...props} />;
}

export const Component = React.memo(Webinar);
